import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Header from "../components/header"
import Link from "../components/link"
import Grid from "../components/dotgrid/grid"
import Row from "../components/dotgrid/row"
import Cell from "../components/dotgrid/cell"
import Spacer from "../components/dotgrid/spacer"

const dots = { color: `#000`, opacity: 0.2 }

const Cta = styled.div.attrs({ className: `styled` })`
  a {
    color: ${(props) => props.theme.colorPurpleLight};
  }
`

const Description = styled.div.attrs({ className: `styled` })`
  ~ ${Cta} {
    margin-top: ${rem(40)};
  }
`

const Error404Page = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    wp,
    siteMessage,
  } = data
  const { title, description, cta } = wp.acf
  const { translations, menus } = pageContext

  metaWpPage.acf.robotsNoIndex = true

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />

      <Header invert={true} />

      <Grid noTopPadding noBottomPadding>
        <Spacer dots={dots} size={4} mq={{ xsmallDown: { size: 2 } }} />
      </Grid>

      <Grid as="section">
        <Row pullY={1} mq={{ xsmallDown: { pullY: 2 } }}>
          <Cell size={10} mq={{ xsmallDown: { size: 23 } }}>
            <h1
              className="styled-h2"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Cell>

          {(description || cta) && (
            <Cell
              size={8}
              pushX={2}
              mq={{
                mediumDown: { size: 11 },
                xsmallDown: { size: 23, pushX: 0, pushY: 2 },
              }}
            >
              {description && (
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}

              {cta && (
                <Cta>
                  <p>
                    <Link to="/" dangerouslySetInnerHTML={{ __html: cta }} />
                  </p>
                </Cta>
              )}
            </Cell>
          )}
        </Row>
      </Grid>
    </Layout>
  )
}

Error404Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Error404Page

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment
    ...HideFooterSectionsPageFragment

    wp: wordpressPage(id: { eq: $id }) {
      acf {
        title: error404_title
        description: error404_description
        cta: error404_cta
      }
    }
  }
`
